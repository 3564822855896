.w-100 {
width:100% !important;
}

.py-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

@media screen and (min-width: 992px) {.px-lg-20px {
padding-left:20px !important;padding-right:20px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-40px {
padding-left:40px !important;padding-right:40px !important;
}

}
 @media screen and (min-width: 992px) {.px-lg-0 {
padding-left:0px !important;padding-right:0px !important;
}

}
 .py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.p-0 {
padding:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.pl-0 {
padding-left:0px !important;
}

.h-100 {
height:100% !important;
}

.mr-10px {
margin-right:10px !important;
}

@media screen and (min-width: 768px) {.mr-md-15px {
margin-right:15px !important;
}

}
 @media screen and (min-width: 992px) {.mr-lg-30px {
margin-right:30px !important;
}

}
 .ml-15px {
margin-left:15px !important;
}

@media screen and (min-width: 992px) {.ml-lg-20px {
margin-left:20px !important;
}

}
 .m-0 {
margin:0px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mt-15px {
margin-top:15px !important;
}

.mr-10px {
margin-right:10px !important;
}

.px-20px {
padding-left:20px !important;padding-right:20px !important;
}

.pb-15px {
padding-bottom:15px !important;
}

@media screen and (min-width: 768px) {.mr-md-10px {
margin-right:10px !important;
}

}
 .m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 1200px) {.pt-xl-100px {
padding-top:100px !important;
}

}
 @media screen and (min-width: 1200px) {.pb-xl-0 {
padding-bottom:0px !important;
}

}
 .mb-25px {
margin-bottom:25px !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.mb-50px {
margin-bottom:50px !important;
}

.mt-20px {
margin-top:20px !important;
}

.mr-10px {
margin-right:10px !important;
}

.mt-20px {
margin-top:20px !important;
}

.mb-50px {
margin-bottom:50px !important;
}

.w-100 {
width:100% !important;
}

.py-75px {
padding-top:75px !important;padding-bottom:75px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.pt-50px {
padding-top:50px !important;
}

.m-0 {
margin:0px !important;
}

.mt-50px {
margin-top:50px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 992px) {.px-lg-50px {
padding-left:50px !important;padding-right:50px !important;
}

}
 .mb-25px {
margin-bottom:25px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.h-100 {
height:100% !important;
}

.mt-n130px {
margin-top:-130px !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

.px-15px {
padding-left:15px !important;padding-right:15px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

@media screen and (min-width: 1200px) {.py-xl-40px {
padding-top:40px !important;padding-bottom:40px !important;
}

}
 .mt-30px {
margin-top:30px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.mt-10px {
margin-top:10px !important;
}

.h-100 {
height:100% !important;
}

@media screen and (min-width: 992px) {.px-lg-50px {
padding-left:50px !important;padding-right:50px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-150px {
padding-left:150px !important;padding-right:150px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-150px {
padding-top:150px !important;padding-bottom:150px !important;
}

}
 .mb-15px {
margin-bottom:15px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.line-height-22px {
line-height:22px !important;
}

.p-0 {
padding:0px !important;
}

@media screen and (min-width: 992px) {.px-lg-50px {
padding-left:50px !important;padding-right:50px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-150px {
padding-left:150px !important;padding-right:150px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 1200px) {.py-xl-130px {
padding-top:130px !important;padding-bottom:130px !important;
}

}
 .mb-5px {
margin-bottom:5px !important;
}

@media screen and (min-width: 992px) {.px-lg-50px {
padding-left:50px !important;padding-right:50px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-150px {
padding-left:150px !important;padding-right:150px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 1200px) {.py-xl-150px {
padding-top:150px !important;padding-bottom:150px !important;
}

}
 .mb-15px {
margin-bottom:15px !important;
}

.mt-20px {
margin-top:20px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .font-size-20px {
font-size:20px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.line-height-30px {
line-height:30px !important;
}

.mt-30px {
margin-top:30px !important;
}

.w-100 {
width:100% !important;
}

@media screen and (min-width: 992px) {.px-lg-0 {
padding-left:0px !important;padding-right:0px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

.h-100 {
height:100% !important;
}

.h-100 {
height:100% !important;
}

@media screen and (min-width: 992px) {.pt-lg-0px {
padding-top:0px !important;
}

}
 .mb-20px {
margin-bottom:20px !important;
}

@media screen and (min-width: 1200px) {.pr-xl-20px {
padding-right:20px !important;
}

}
 @media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 @media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-50px {
padding-bottom:50px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mt-50px {
margin-top:50px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 768px) {.px-md-3 {
padding-left:3px !important;padding-right:3px !important;
}

}
 @media screen and (min-width: 992px) {.px-lg-5 {
padding-left:5px !important;padding-right:5px !important;
}

}
 .mb-5 {
margin-bottom:5px !important;
}

@media screen and (min-width: 768px) {.mb-md-0 {
margin-bottom:0px !important;
}

}
 .pb-20px {
padding-bottom:20px !important;
}

.m-0 {
margin:0px !important;
}

.pb-3 {
padding-bottom:3px !important;
}

@media screen and (min-width: 992px) {.pb-lg-2 {
padding-bottom:2px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.mx-2 {
margin-left:2px !important;margin-right:2px !important;
}

.opacity-25 {
opacity:25 !important;
}

.p-0 {
padding:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.p-0 {
padding:0px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .ml-5px {
margin-left:5px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mr-5px {
margin-right:5px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

