html {
	overflow-y: scroll !important;
}

.menu-toggle {
	span {
		position: relative;
		width: 22px;
		height: 22px;
		line-height: 22px;
		display: inline-block;
	}
}

.lines-button:after {
	background-color: rgba(0, 0, 0, 0.4);
	display: inline-block;
	width: 1.4rem;
	height: 2px;
	transition: transform 0.45s ease, opacity 0.2s ease,
		background-color 0.2s linear;
	-webkit-transition: -webkit-transform 0.45s ease, opacity 0.2s ease,
		background-color 0.2s ease;
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
}

.lines-button {
	transition: 0.3s;
	cursor: pointer;
	top: 9px;
	position: relative;
	user-select: none;
	display: block;
}

.lines:before,
.lines:after {
	display: block;
	width: 1.4rem;
	height: 2px;
	background: #ecf0f1;
	transition: 0.3s;
	position: absolute;
	left: 0;
	content: "";
	-webkit-transform-origin: 0.142rem center;
	transform-origin: 0.142rem center;
}
.lines:before {
	background-color: #888888;
	top: 6px;
	width: 1rem;
}
.lines:after {
	background-color: #888888;
	top: -6px;
}

/*things to hide when material ocm is open*/
.ocm-effect-wrap.mobile-nav-show .nectar-social.fixed {
	-webkit-transform: scale(0);
	transform: scale(0);
}
.ocm-effect-wrap.mobile-nav-show .swiper-container .slider-prev,
.ocm-effect-wrap.mobile-nav-show .swiper-container .slider-next {
	transition: background-color 0.2s linear,
		opacity 0.45s cubic-bezier(0.15, 0.2, 0.1, 1);
	-webkit-transition: background-color 0.2s linear,
		opacity 0.45s cubic-bezier(0.15, 0.2, 0.1, 1);
}
.ocm-effect-wrap.mobile-nav-show
	.nectar-slider-wrap[data-full-width="true"]
	.swiper-container
	.slider-prev,
.ocm-effect-wrap.mobile-nav-show
	.nectar-slider-wrap[data-full-width="true"]
	.swiper-container
	.slider-next {
	opacity: 0 !important;
}
#fp-nav li {
	transition: transform 0.3s ease;
	-webkit-transition: transform 0.3s ease;
}
#fp-nav.mobile-nav-show li {
	-webkit-transform: scale(0);
	transform: scale(0);
}
body
	#slide-out-widget-area.slide-out-from-right
	.off-canvas-menu-container
	.current-menu-item
	> a {
	opacity: 1;
}
#slide-out-widget-area.slide-out-from-right,
#slide-out-widget-area.slide-out-from-right:not(.fullscreen-alt):not(.fullscreen) {
	background-color: transparent !important;
}
#slide-out-widget-area[class*="slide-out-from-right"]
	.off-canvas-menu-container
	li
	a {
	font-size: 22px;
	line-height: 28px;
	font-family: "Open Sans";
	font-weight: 400;
}
#slide-out-widget-area-bg.slide-out-from-right {
	opacity: 1;
	height: 100%;
	width: 100%;
	z-index: 1;
}

/*before div gets moved*/
#ajax-content-wrap > #slide-out-widget-area-bg.slide-out-from-right {
	visibility: hidden;
}
body #slide-out-widget-area-bg.slide-out-from-right.material-open,
body #slide-out-widget-area.slide-out-from-right.material-open {
	opacity: 1;
	pointer-events: auto;
}
body .ocm-effect-wrap {
	background-color: transparent;
}
body.mobile-nav-show .ocm-effect-wrap-inner {
	padding-top: 0 !important;
}
body.mobile-nav-show div[id="wpadminbar"] {
	opacity: 0;
}
body.mobile-nav-show #header-outer {
	top: 0 !important;
}
body.mobile-nav-show {
	background-attachment: fixed;
}
body #slide-out-widget-area.slide-out-from-right {
	-webkit-transform: none;
	transform: none;
}
body .ocm-effect-wrap {
	-ms-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-ms-transform-origin: right;
	-webkit-transform-origin: right;
	transform-origin: right;
	overflow: hidden;
	position: relative;
	z-index: 10;
}
body .ocm-effect-wrap-shadow {
	-ms-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-ms-transform-origin: right;
	-webkit-transform-origin: right;
	transform-origin: right;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	z-index: 1;
	box-shadow: 0 80px 145px rgba(0, 0, 0, 0.33);
}

/* Safari admin bar fix with OCM */
@media only screen and (max-width: 600px) {
	body.admin-bar:not(.mobile-nav-show) .ocm-effect-wrap {
		position: static;
	}
}

.ocm-effect-wrap-inner {
	height: 100%;
}

body.admin-bar.mobile-nav-show {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	top: 0;
}
.slide-out-widget-area-toggle .lines-button.x2 .lines:before,
.slide-out-widget-area-toggle .lines-button.x2 .lines:after {
	transition: top 0.4s 0.45s ease, width 0.3s ease, -webkit-transform 0.3s ease,
		background-color 0.2s ease, opacity 0.2s ease;
	transition: top 0.4s 0.45s ease, width 0.3s ease, transform 0.3s ease,
		background-color 0.2s ease, opacity 0.2s ease;
}
.lines:after,
body
	.slide-out-hover-icon-effect.slide-out-widget-area-toggle.small
	.lines:after {
	top: -7px;
}
.lines:before,
body
	.slide-out-hover-icon-effect.slide-out-widget-area-toggle.small
	.lines:before {
	width: 1rem;
	top: 7px;
}
body .slide-out-widget-area-toggle.mobile-icon .lines:before,
body #header-outer .slide-out-widget-area-toggle.mobile-icon .lines:before {
	width: 1rem !important;
}
.slide-out-widget-area-toggle .lines-button.close .lines:before {
	-webkit-transform: translateY(-7px) rotateZ(-45deg);
	-ms-transform: translateY(-7px) rotateZ(-45deg);
	transform: translateY(-7px) rotateZ(-45deg);
	width: 1.5rem;
}
.slide-out-widget-area-toggle .lines-button.close .lines:after {
	-webkit-transform: translateY(7px) rotateZ(45deg);
	-ms-transform: translateY(7px) rotateZ(45deg);
	transform: translateY(7px) rotateZ(45deg);
	width: 1.5rem;
}
.material .lines-button.close {
	transform: none;
}
body #header-outer .icon-salient-search:before,
body #header-outer .icon-salient-search:before {
	content: "\e906";
}

.admin-bar[class*="bp-"] .ocm-effect-wrap-inner,
.admin-bar[class*="bp-"] .ocm-effect-wrap {
	border-radius: initial;
}

.mobile-nav-show .container-wrap {
	pointer-events: none;
}

.ocm-effect-wrap.mobile-nav-show .ocm-effect-wrap-inner {
	-webkit-transform: scale(1.007) !important;
	transform: scale(1.007) !important;
	-webkit-transform-origin: center;
	transform-origin: center;
}

body .ocm-effect-wrap.mobile-nav-show {
	-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transform: scale(0.835) translateX(-466px) translateZ(0) !important;
	transform: scale(0.835) translateX(-466px) translateZ(0) !important;
	height: 100%;
}

body .ocm-effect-wrap:not(.mobile-nav-show) {
	-webkit-transform: none !important;
	transform: none !important;
	height: 100%;
	transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
}

@media only screen and (max-width: 450px) {
	#slide-out-widget-area.slide-out-from-right {
		min-width: 78vw;
		width: 78vw;
		padding-left: 50px;
		padding-right: 50px;
		-ms-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
		-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
		transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	}
	body .ocm-effect-wrap-shadow {
		-webkit-transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
		transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	}
	body .ocm-effect-wrap.mobile-nav-show {
		-webkit-transform: scale(0.84) translateX(-93vw) translateZ(0) !important;
		transform: scale(0.84) translateX(-93vw) translateZ(0) !important;
	}
	body .ocm-effect-wrap-shadow.mobile-nav-show {
		-webkit-transform: scale(0.83) translateX(-94vw) translateZ(0) !important;
		transform: scale(0.83) translateX(-94vw) translateZ(0) !important;
	}
}

@media only screen and (min-width: 1100px) {
	#slide-out-widget-area.slide-out-from-right {
		min-width: 33.3vw;
		padding-left: 4%;
		padding-right: 4%;
	}
	body .ocm-effect-wrap.mobile-nav-show {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: scale(0.8) translateX(-32vw) translateZ(0) !important;
		transform: scale(0.8) translateX(-32vw) translateZ(0) !important;
	}
	body .ocm-effect-wrap-shadow.mobile-nav-show {
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: scale(0.79) translateX(-33.6vw) translateZ(0) !important;
		transform: scale(0.79) translateX(-33.6vw) translateZ(0) !important;
	}
	body .ocm-effect-wrap.mobile-nav-show {
		-ms-transform-origin: center;
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transform: scale(0.85) translateX(-29.6vw) translateZ(0) !important;
		transform: scale(0.85) translateX(-29.6vw) translateZ(0) !important;
	}
	body .ocm-effect-wrap.mobile-nav-show .ocm-effect-wrap-inner {
		-webkit-transform: none !important;
		transform: none !important;
	}
}

@media only screen and (max-width: 999px) and (min-width: 691px) {
	body .nectar-button.extra_jumbo.has-icon {
		font-size: 30px;
		line-height: 60px;
		padding: 30px 100px 30px 60px;
	}

	body .nectar-button.has-icon.extra_jumbo i {
		height: 74px;
		width: 74px;
		line-height: 74px;
	}
}

.mobile-nav-show #ajax-content-wrap {
	cursor: pointer;
}
#slide-out-widget-area.slide-out-from-right .inner > div,
#slide-out-widget-area.slide-out-from-right .bottom-meta-wrap {
	-webkit-transform: translateX(110px);
	-webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.3, 1);
	transform: translateX(110px);
	transition: all 0.8s cubic-bezier(0.2, 1, 0.3, 1);
}
#slide-out-widget-area.slide-out-from-right.material-open .inner > div,
#slide-out-widget-area.slide-out-from-right.material-open .bottom-meta-wrap {
	-ms-transform: translateX(0px);
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
}
body
	#slide-out-widget-area[class*="slide-out-from-right"]
	.off-canvas-menu-container
	li {
	margin-bottom: 12px;
}
body
	#slide-out-widget-area.slide-out-from-right
	.off-canvas-menu-container
	li
	a {
	position: relative;
}
body
	#slide-out-widget-area.slide-out-from-right
	.off-canvas-menu-container
	> .menu
	> li
	a {
	opacity: 1;
	transition: color 0.37s cubic-bezier(0.52, 0.01, 0.16, 1);
}
body
	#slide-out-widget-area[class*="slide-out-from-right"]
	.off-canvas-menu-container
	li
	a:after {
	transform-origin: left;
	pointer-events: none;
	bottom: -2px;
	-webkit-transition: -webkit-transform 0.37s cubic-bezier(0.52, 0.01, 0.16, 1);
	transition: transform 0.37s cubic-bezier(0.52, 0.01, 0.16, 1);
	border-color: #fff;
}
body
	#slide-out-widget-area.slide-out-from-right-hover
	.inner
	.off-canvas-menu-container
	li
	a {
	color: #fff;
}
input[type="text"],
textarea,
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="date"],
.material.woocommerce-page[data-form-style="default"] input#coupon_code {
	background-color: rgba(0, 0, 0, 0.04) !important;
	border-radius: 4px !important;
	border: 2px solid rgba(0, 0, 0, 0);
}
input[type="text"]:focus,
textarea:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="date"]:focus {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
}

#slide-out-widget-area-bg.slide-out-from-right {
	background-color: #3A5A40;
	position: fixed;
	left: 0;
	top: 0;
}
